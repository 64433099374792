.showContent div div {
    padding: 15px;
    left: 30px !important;

}

.showContent .title {
    margin: 0px !important;
}

.showContent .subtitle {
    opacity: 0.7;
    font-size: 12px;
}

.showContent .phoneObject {
    font-size: 13px;
}

.showContent .fieldLabel {
    padding: 0px !important;
    font-size: 0.75em;
    margin-bottom: 0.2em;
    opacity: 0.6;
}

.showContent .fullNameLabel {
    letter-spacing: 1px;
    font-size: 22px;
    font-weight: 300;
}

.showContent .subHeader {
    padding: 25px 0px 0px 30px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(2, 85, 163, 0.8)
}

.showContent .subHeader2 {
    padding: 0px 0px 0px 30px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(2, 85, 163, 0.8)
}

.investmentExperience {
    padding: 0px 0px 3px 0px !important;
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(0.2turn, rgba(218, 221, 224), rgba(2, 85, 163), rgba(2, 92, 177, 0));
    border-image-slice: 1;
}

.investmentExperience span {
    font-size: 15px;
    padding-left: 5px;
    font-weight: 600;
}

.investmentExperience div {
    padding: 5px !important;
}

.investmentExperience div div {
    padding: 2px !important;
}

.investmentExperience .fieldTitle {
    font-size: 12px;
    font-style: italic;

}

.investmentExperience .fieldValue {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-left: 3px;
}

.MuiTabs-scroller {
    overflow-x: auto !important;
    scrollbar-width: none;
    /* Firefox */
}

.MuiTabs-scroller::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.fieldLabel {
    font-size: 0.75em;
    margin-bottom: 0.2em;
    opacity: 0.6;
}



.achRelationship {
    margin-top: 10px;
    padding: 15px 10px 15px 10px !important;
    /* border-bottom: 2px solid transparent; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    /* 5px rounded corners */
}

.achRelationship:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.achRelationship .status{
    font-size: 14px;
    padding:5px;
    border-radius: 5px;
    font-weight: 600;
}

.achRelationship .description {
    font-size: 13px;
    opacity: 0.6
}

.alignVerticalCenter {
    display: flex;
    align-items: center;
}

.alignAllCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.customButton {
    transition-duration: 0.4s;
    background-color: #ffff; /* Green */
    border: none;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    display: inline-flex;
    align-items: center; 
    border-radius: 10;
}

.customButton:hover {
    background-color: #f3f3f3;
    /* Green */
}

.updateRequestItem {


    margin:  10px !important;
    padding: 15px 10px 15px 10px !important;
    /* border-bottom: 2px solid transparent; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    /* 5px rounded corners */
}

.updateRequestItem .sectionTitle {
    padding: 10px 0px 15px 30px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(2, 85, 163, 0.8)
}

.updateRequestItem .keyContainer {
    padding:15px;
    margin-bottom: 15px;
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(0.2turn, rgba(218, 221, 224), rgba(2, 85, 163), rgba(2, 92, 177, 0));
    border-image-slice: 1;
}

.updateRequestItem .keyHeader {
    padding-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
    opacity: 0.6;
}

.updateRequestItem .description {
    opacity: 0.7;
    font-size: 14px;
}
.updateRequestItem .keyOriginalValue {
    text-align: center;
    /* padding: 25px 0px 0px 30px; */
    padding:3px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(173, 78, 0, 0.8)
}

.updateRequestItem .keyNewValue {
    text-align: center;
    /* padding: 25px 0px 0px 30px; */
    padding:5px;
    font-size: 18px;
    font-weight: 500;
    color: rgba(35, 120, 4, 0.8)
}


.investigationBox {
    margin-top: 10px;
    padding: 5px 3px 5px 3px !important;
    /* border-bottom: 2px solid transparent; */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    /* 5px rounded corners */
}

.hoverItem {
    margin-top: 10px;
    padding: 15px 10px 15px 10px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
}

.hoverItem .description {
    font-size: 13px;
    opacity: 0.6
}